/* Variables de color y tipografía */
:root {
    --dark-brown: #322D29;
    --dark-beige: #AC9C8D;
    --beige: #D1C7BD;
    --lite-beige: #EFE9E1;
    --bordeau: #72383D;
    --white: #FFF;
    --black: #000;
    --input-placeholder-color: #B1B1B1;
    --input-height: 24px;
    --input-border-radius: 4px;
    --input-padding-left: 28px;
    --input-icon-size: 16px;
    --input-border-color: #e9e9e9;
    --font-family: 'Poppins', sans-serif;
    --btn-padding-x: 18px;
    --btn-padding-y: 8px;
    --btn-margin-top: 12px;
    --btn-hover-color: #8b4047;
    --img-margin-y: 12px;
    --section-padding-y: 64px;
    --container-padding-mobile-x: 24px;
    /* Font Sizes */
    --font-size-base: 1rem; /* 18px */
    --font-size-h1: 3.5rem; /* 42px */
    --font-size-h2: 2.375rem; /* 38px */
    --font-size-h3: 1.5rem; /* 24px */
    --font-size-h4: 1.125rem; /* 18px */

    --video-border-radius: clamp(10px, 15vw, 200px)
}

/* Reset básico */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Tipografía */
body {
    font-family: var(--font-family);
    color: var(--black);
    font-size: var(--font-size-base);
}

/* Heading Styles */
h1 {
    font-size: var(--font-size-h1);
}

h2 {
    font-size: var(--font-size-h2);
}

h3 {
    font-size: var(--font-size-h3);
}

h4 {
    font-size: var(--font-size-h4);
}

p {
    font-size: var(--font-size-base);
}


/* Grid */
.container {
    max-width: 1200px; /* Ajusta según necesidad */
    margin: 0 auto;
}

.flex-grid {
    display: flex;
    gap: 64px;
    align-items: flex-start;
}

.col {
    flex: 1;
}

/* Helpers */
.text-center {
    text-align: center;
}

.y-center {
    align-content: center;
    align-items: center;
}

/* Imágenes */
img {
    max-width: 100%;
}


/* Hero */
.hero {
    position: relative;
    min-height: 55vh;
    background-image: url('./images/hero-image-reduced.jpg'); /* Reemplaza con la ruta de tu imagen */
    background-size: cover;
    background-position: center;
    display: flex;
    padding-top: 8rem;
    padding-bottom: 2rem;
    justify-content: center;
    color: var(--white);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
}

.hero-content {
    position: relative;
    text-align: center;
    max-width: 1200px;
    padding: 0 20px;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.hero h1 {
    margin-bottom: 20px;
    font-weight: 600;
}

.hero p {
    margin-bottom: 40px;
    line-height: 1.5;
    font-size: var(--font-size-h4);
}

.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

    .hero-buttons .btn.primary {
        border: 2px solid var(--white);
    }

.btn {
    padding: var(--btn-padding-y) var(--btn-padding-x);
    margin-top: var(--btn-margin-top);
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    min-height: 48px;
}

    .btn.primary {
        background-color: var(--bordeau);
        color: var(--white);
    }

        .btn.primary a {
            width: 100%;
        }

        .btn.primary:hover {
            background-color: var(--btn-hover-color);
        }

    .btn.outline {
        background-color: transparent;
        border: 2px solid var(--white);
        color: var(--white);
    }

        .btn.outline:hover {
            background-color: var(--white);
            color: var(--bordeau);
        }

.hero .btn.primary {
    border: none;
}
/* Search */
.search-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--section-padding-y) 0px;
}

.lead-text {
    color: var(--dark-brown);
    font-size: var(--font-size-base);
}

.search-image {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

    .search-image img {
        box-shadow: 0px 0px 24px 1px rgba(50,45,41,0.16);
        -webkit-box-shadow: 0px 0px 24px 1px rgba(50,45,41,0.16);
        -moz-box-shadow: 0px 0px 24px 1px rgba(50,45,41,0.16);
    }

.search-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: var(--img-margin-y);
}

.search-location {
    background-color: var(--lite-beige);
}

.search-bar {
    background-color: var(--white);
    border: 2px solid var(--input-border-color);
    box-sizing: border-box;
    justify-content: flex-start;
    align-self: stretch;
    align-items: center;
    border-radius: 8px;
    flex-direction: row;
    max-width: 100%;
    padding: .625rem .687rem;
    display: flex;
    margin-top: var(--btn-margin-top);
}

.search-content .btn.primary {
    align-self: baseline;
    min-width: 50px;
}

.input-field {
    color: var(--input-placeholder-color);
    text-align: left;
    background-color: transparent;
    flex: 1;
    height: 24px;
    padding: 12px;
    border: 0;
    outline: 0;
}

/* New Section: Process of Finding an Agent */
.process-finding-agent {
    background-color: var(--white);
    padding: var(--section-padding-y) 0;
}

    .process-finding-agent h2 {
        margin-bottom: 12px;
    }

    .process-finding-agent .lead-text {
        font-size: var(--font-size-base);
    }

.title-container {
    margin-bottom: var(--img-margin-y);
}

.process-finding-agent .lead-text {
    font-size: var(--font-size-h4);
    font-weight: 600
}

.process-finding-agent .col p {
    margin-bottom: 16px;
    line-height: 1.6;
}

.process-finding-agent .col img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* New Section: The Agent List Standard */
.agent-list-standard {
    background-color: var(--lite-beige);
    padding: var(--section-padding-y) 0;
}

    .agent-list-standard .row h2 {
        text-align: center;
        margin-bottom: 32px;
    }

    .agent-list-standard .flex-grid {
        display: flex;
        align-items: flex-start;
    }

    .agent-list-standard .col img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
    }

    .agent-list-standard .col .standard-item-row {
        display: flex;
        align-items: flex-start;
        gap: 16px; /* Space between icon and text */
        margin-bottom: 24px; /* Space below each standard item row */
    }

    .agent-list-standard .icon-box {
        background-color: var(--white);
        padding: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        flex-shrink: 0; /* Prevent the icon box from shrinking */
        height: 72px;
        width: 72px;
    }

    .agent-list-standard .standard-icon {
        width: 42px; /* Adjust size as needed */
        height: 42px;
        /* Additional styles specific to standard icons */
    }

    .agent-list-standard .standard-item h3 {
        font-size: var(--font-size-h3); /* 1.5rem / 24px */
        margin-bottom: 8px;
        color: var(--dark-brown);
    }

    .agent-list-standard .standard-item p {
        font-size: var(--font-size-base);
        line-height: 1.6;
        color: var(--black);
    }

    .agent-list-standard .btn.primary {
        margin-top: 24px;
        align-self: flex-start;
    }

    .agent-list-standard h2 {
        margin-bottom: 12px;
    }

    .agent-list-standard .lead-text {
        font-size: var(--font-size-h4);
        font-weight: 600
    }

    .agent-list-standard .col p {
        margin-bottom: 16px;
        line-height: 1.6;
    }


/* New Section: Referral Commission */
.referral-commission {
    background-color: var(--white); /* You can change this to fit your design */
    padding: var(--section-padding-y) 0;
}

.commission-box {
    background-color: var(--lite-beige); /* Background color of the box */
    border-radius: 12px; /* Rounded corners */
    padding: 32px; /* Internal padding */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 768px;
    text-align: center;
}

    .commission-box h3 {
        font-size: var(--font-size-h3); /* 1.5rem / 24px */
        color: var(--dark-brown);
    }

    .commission-box .lead-text {
        font-size: var(--font-size-base); /* 1.125rem / 18px */
    }

    .commission-box .refer-button {
        border: 2px solid var(--white); /* White border */
        background-color: var(--bordeau); /* Maintain primary button background */
        color: var(--white);
        padding: 12px 24px;
        border-radius: 50px;
        transition: background-color 0.3s, border 0.3s;
        cursor: pointer;
    }



.referral-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Meet Your Agent Section */
.meet-your-agent {
    background-color: var(--black); /* Black background */
    border-top-right-radius: var(--video-border-radius); /* Rounded top-right corner */
    border-bottom-left-radius: var(--video-border-radius); /* Rounded bottom-left corner */
    padding: var(--section-padding-y) 0; /* Vertical padding */
    color: var(--white); /* White text for contrast */
    position: relative;
    overflow: hidden; /* Ensures content doesn't overflow the rounded corners */
}

    .meet-your-agent .lead-text {
        font-size: var(--font-size-h4);
        color: var(--white);
        max-width: 800px; /* Optional: Limit the width for better readability */
        margin: 0 auto; /* Center the paragraph */
        line-height: 1.6;
    }

.video-content {
    padding: 0 8%;
}

.video-container {
    max-width: 1200px;
    margin: 0 auto;
}

.video-responsive {
    width: 100%;
    padding: 50.25% 0 0;
    position: relative;
    overflow: hidden;
    border-radius: 0 0 0 5vw;
}

    .video-responsive iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

/* Estilos específicos para la sección Why Choose Us */
.why-choose-us {
    padding: var(--section-padding-y) 0px;
}

    .why-choose-us .container .col {
        display: flex;
        justify-content: center;
    }

    .why-choose-us .btn.primary {
        border: 2px solid var(--white);
    }


/* Social Section */
.social {
    background-color: var(--white); /* Fondo acorde al diseño */
    padding: var(--section-padding-y) 0; /* Padding vertical */
}

.social-box {
    max-width: 750px;
    margin: 0 auto; /* Centrar la caja dentro de la columna */
}

    .social-box h2 {
        font-size: var(--font-size-h2);
        color: var(--dark-brown);
        margin-bottom: 1rem;
    }

    .social-box .lead-text {
        font-size: 1.125rem; /* 18px */
        margin-bottom: 2rem;
    }

.flex-list {
    list-style: none; /* Eliminar los marcadores de lista */
    display: flex;
    flex-direction: row;
    justify-content: center; /* Centrar los íconos horizontalmente */
    gap: 20px; /* Espacio entre íconos */
    padding: 0; /* Eliminar padding por defecto de ul */
    margin: 0; /* Eliminar margen por defecto de ul */
}

.social-icon img {
    width: 56px; /* Ancho de los íconos */
    height: 56px; /* Alto de los íconos */
    transition: transform 0.3s; /* Animación suave al pasar el cursor */
}

.social-icon:hover img {
    transform: scale(1.1); /* Aumentar ligeramente el tamaño al pasar el cursor */
}

/* Footer */
.footer .flex-grid {
    align-items: center;
}

.footer .footer-top {
    background-color: var(--lite-beige); /* Fondo de la primera fila */
    padding: var(--section-padding-y) 0;
}

.footer .footer-bottom {
    background-color: var(--white); /* Fondo predeterminado */
    padding: 20px 0;
    text-align: center;
    color: var(--dark-brown);
    font-size: var(--font-size-base);
}

.footer-logo img {
    width: 150px; /* Ajusta el tamaño del logo según necesidad */
    height: auto;
}

.footer-menu li a {
    text-decoration: none;
    color: var(--dark-brown);
    transition: color 0.3s;
}

    .footer-menu li a:hover {
        color: var(--bordeau);
    }

/* Hide mobile navbar */
/* Estilos para la Navegación Móvil */
.mobile-navbar {
    display: none;
}

.collapsable-menu {
    display: none;
}

/* Navegación */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 90;
    background-color: transparent; /* Asegúrate de que el fondo sea transparente o ajusta según necesidad */
}

.navbar-desktop {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.logo {
    min-width: 150px;
    text-align: center;
}

    .logo a {
        font-size: var(--font-size-h3);
        font-weight: 700;
        color: var(--dark-brown);
        text-decoration: none;
    }

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    background-color: var(--lite-beige);
    padding: 12px;
    border-radius: 50px;
    height: 64px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

    .nav-links li a {
        text-decoration: none;
        color: var(--black);
        padding: 10px 20px;
        border-radius: 50px;
        background-color: var(--lite-beige);
        transition: background-color 0.3s, color 0.3s;
    }

        .nav-links li a:hover {
            color: var(--bordeau);
            background-color: var(--beige);
        }

.login-button {
    display: block;
    text-align: center;
    min-width: 150px;
}

    .login-button button {
        display: inline-block;
        text-decoration: none;
        color: var(--white);
        background-color: var(--bordeau);
        padding: 8px 12px;
        border-radius: 200px;
        transition: background-color 0.3s;
        max-width: 120px;
        border: 2px solid var(--white);
        min-width: 6rem;
        text-align: center;
    }

        .login-button button:hover {
            background-color: var(--btn-hover-color);
        }

.right-nav-col {
    flex-direction: row;
    display: flex;
    gap: 1rem;
    align-items: center;
}

/* Menu hambuerger*/
.ham-icon {
    height: 2rem;
    width: 1.75rem;
    position: relative;
}

    .ham-icon span {
        display: block;
        height: 3px;
        width: 100%;
        background-color: var(--black);
        border-radius: 1.75rem;
        position: absolute;
        top: 50%;
        left: 50%; /* Changed from 0 to 50% for proper centering */
        transform: translate(-50%, -50%); /* Added comma for correct syntax */
        transition: transform 0.3s ease-in-out;
    }

        .ham-icon span:nth-child(1) {
            top: 25%;
        }

        .ham-icon span:nth-child(3) {
            top: 75%;
        }
    /* Optional: Animate the hamburger icon to an 'X' when active */
    .ham-icon.active span:nth-child(1) {
        transform: rotate(45deg) translate(7px, 7px);
        left: 0;
    }

    .ham-icon.active span:nth-child(2) {
        opacity: 0;
    }

    .ham-icon.active span:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
        left: 0;
    }

.collapsable-menu {
    max-height: 0; /* Start with max-height 0 */
    opacity: 0; /* Start fully transparent */
    overflow: hidden; /* Hide overflowing content */
    flex-direction: column;
    transition: max-height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    transform: translateY(-20px); /* Start slightly above */
}

    .collapsable-menu.active {
        max-height: 500px; /* Set to a value that can accommodate the menu */
        opacity: 1; /* Fully visible */
        transform: translateY(0); /* Slide into place */
    }



/*Menu*/
@media (max-width: 870px) {
    /* Mobile navbar*/
    .top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
    }

    .navbar {
        display: flex;
        padding: 8px 12px;
        background-color: var(--beige);
        height: auto;
        width: 100%;
        text-align: center;
        z-index: 99;
        transition: transform 0.5s ease;
        flex-direction: column;
        align-items: baseline;
    }

    .mobile-navbar {
        display: flex;
    }

    .collapsable-menu {
        display: flex;
        align-self: center;
    }

        .collapsable-menu .nav-links {
            background-color: transparent;
            height: auto;
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 1rem;
            flex-direction: column;
            display: flex;
            margin-bottom: 1rem;
        }

        .collapsable-menu nav-links li {
            width: 100%;
        }

        .collapsable-menu .nav-links li a {
            background-color: transparent;
            width: 100%;
            text-align: center;
            font-weight: 600;
            font-size: var(--font-size-h4);
        }

    .mobile-navbar .logo {
        max-width: 100px;
        min-width: auto;
    }

    .right-nav-col {
        display: block;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    .navbar .login-button {
        display: flex;
        align-self: center;
        width: 100%;
    }

        .navbar .login-button button {
            width: 100%;
        }

    /*fin nav mobile*/

    /*Hide common navbar*/
    .navbar-desktop {
        display: none;
    }

    .hero-buttons {
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }

    .login-button {
        min-width: auto;
    }

    .logo {
        margin: 0 1rem;
    }
}

@media (max-width: 1200px) {
    .container {
        padding: 0 var(--container-padding-mobile-x);
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 768px;
    }

    .search-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .hero-content {
        flex-wrap: wrap-reverse;
        justify-content: center;
        gap: 1rem;
    }

    .btn {
        justify-content: center;
    }

    .search-container .btn.primary {
        display: flex;
        align-self: self-start;
    }

    .flex-grid {
        flex-direction: column;
        gap: 16px;
    }

    /* Finding an Agent */
    .finding-content.flex-grid {
        text-align: center;
        align-items: center;
    }

    /* Agent List Standard */
    .agent-list-standard .col {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

        .agent-list-standard .col img.agent-list-standard-image {
            max-width: 350px;
            width: 100%;
        }

    .agent-list-standard .flex-grid {
        flex-direction: column;
        align-items: center;
    }

    .agent-list-standard .col {
        width: 100%;
    }

        .agent-list-standard .col .btn.primary {
            width: 100%;
            justify-content: center;
        }

        .agent-list-standard .col .standard-item-row {
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 480px;
        }

    .agent-list-standard .icon-box {
        padding: 12px;
        height: 100px;
        width: 100%;
        max-width: 100px;
        align-items: center;
        align-content: center;
    }

    .icon-box {
        margin-bottom: 16px;
    }

    .standard-item {
        max-width: 100%;
    }

    /* Referral Commission */
    .referral-commission .flex-grid {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .referral-commission .col {
        width: 100%;
        justify-content: center;
    }

    .referral-commission .commission-box {
        align-items: center;
        text-align: center;
    }

        .referral-commission .commission-box .refer-button {
            justify-content: center;
        }

    .referral-commission .referral-image {
        margin-top: 24px;
    }

    /* Meet Your Agent */
    .meet-your-agent {
        padding: 32px 0;
        border-top-right-radius: var(--video-border-radius);
        border-bottom-left-radius: var(--video-border-radius);
    }

        .meet-your-agent .lead-text {
            font-size: var(--font-size-base);
            max-width: 600px;
            color: var(--white);
        }

        .meet-your-agent h2 {
            font-size: var(--font-size-h2);
        }

    /* Social Network */
    .flex-list {
        flex-wrap: wrap; /* Permitir que los íconos se ajusten en múltiples líneas */
        gap: 15px; /* Reducir el espacio entre íconos en pantallas más pequeñas */
    }

    .social-icon img {
        width: 35px;
        height: 35px;
    }

    /* Footer */
    .footer .flex-grid {
        text-align: center;
    }
}

/* Responsive */
@media (min-width: 1440px) {
    .hero {
        min-height: 55vh;
    }
}


@media (max-width: 768px) {
    :root {
        --font-size-base: 0.875rem; /* Reducción de base para textos pequeños */
        --font-size-h1: 2.5rem; /* H1 menos dominante en móvil */
        --font-size-h2: 2rem; /* Ajuste de H2 para alinearse con H1 reducido */
        --font-size-h3: 1.25rem; /* Tamaño reducido para H3 */
        --font-size-h4: 1rem; /* Similar al tamaño base pero con ligera diferencia */
    }

    /* Footer */
    .footer-menu.flex-list {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    :root {
        --font-size-base: 0.875rem; /* Reducción de base para textos pequeños */
        --font-size-h1: 2.5rem; /* H1 menos dominante en móvil */
        --font-size-h2: 2rem; /* Ajuste de H2 para alinearse con H1 reducido */
        --font-size-h3: 1.25rem; /* Tamaño reducido para H3 */
        --font-size-h4: 1rem; /* Similar al tamaño base pero con ligera diferencia */
    }

    .btn {
        padding: var(--btn-padding-y) var(--btn-padding-y);
    }
    /* Search */
    .search-container .btn.primary {
        display: flex;
        align-self: stretch;
    }
    /* Agent List Standard */
    .agent-list-standard {
        padding: 2rem 0;
    }


        .agent-list-standard .standard-icon {
            width: 42px; /* Smaller size on mobile */
            height: 42px;
        }

        .agent-list-standard .btn.primary {
            margin-top: 16px;
        }
    /* Referral Commission */
    .referral-commission {
        padding: 2rem 0;
    }

    .commission-box {
        padding: 1.5rem;
    }

        .commission-box .refer-button {
            padding: 10px 20px;
        }

    /* Meet Your Agent */
    .meet-your-agent {
        padding: 48px 0;
        border-top-right-radius: var(--video-border-radius);
        border-bottom-left-radius: var(--video-border-radius);
    }

    /* Social Section */
    .flex-list {
        gap: 0.75rem; /* Reducir aún más el espacio entre íconos en móviles */
    }

    .social-icon img {
        width: 30px;
        height: 30px;
    }
}

@media (max-height: 900px) {
    .hero {
        min-height: 60vh;
    }
}
