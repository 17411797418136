:root {
  --dark-brown: #322d29;
  --dark-beige: #ac9c8d;
  --beige: #d1c7bd;
  --lite-beige: #efe9e1;
  --bordeau: #72383d;
  --white: #fff;
  --black: #000;
  --input-placeholder-color: #b1b1b1;
  --input-height: 24px;
  --input-border-radius: 4px;
  --input-padding-left: 28px;
  --input-icon-size: 16px;
  --input-border-color: #e9e9e9;
  --font-family: "Poppins", sans-serif;
  --btn-padding-x: 18px;
  --btn-padding-y: 8px;
  --btn-margin-top: 12px;
  --btn-hover-color: #8b4047;
  --img-margin-y: 12px;
  --section-padding-y: 64px;
  --container-padding-mobile-x: 24px;
  --font-size-base: 1rem;
  --font-size-h1: 3.5rem;
  --font-size-h2: 2.375rem;
  --font-size-h3: 1.5rem;
  --font-size-h4: 1.125rem;
  --video-border-radius: clamp(10px, 15vw, 200px);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family);
  color: var(--black);
  font-size: var(--font-size-base);
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

p {
  font-size: var(--font-size-base);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.flex-grid {
  align-items: flex-start;
  gap: 64px;
  display: flex;
}

.col {
  flex: 1;
}

.text-center {
  text-align: center;
}

.y-center {
  align-content: center;
  align-items: center;
}

img {
  max-width: 100%;
}

.hero {
  color: var(--white);
  background-image: url("hero-image-reduced.d611614d.jpg");
  background-position: center;
  background-size: cover;
  justify-content: center;
  min-height: 55vh;
  padding-top: 8rem;
  padding-bottom: 2rem;
  display: flex;
  position: relative;
}

.overlay {
  background-color: #000c;
  position: absolute;
  inset: 0;
}

.hero-content {
  text-align: center;
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.hero h1 {
  margin-bottom: 20px;
  font-weight: 600;
}

.hero p {
  line-height: 1.5;
  font-size: var(--font-size-h4);
  margin-bottom: 40px;
}

.hero-buttons {
  justify-content: center;
  gap: 20px;
  display: flex;
}

.hero-buttons .btn.primary {
  border: 2px solid var(--white);
}

.btn {
  padding: var(--btn-padding-y) var(--btn-padding-x);
  margin-top: var(--btn-margin-top);
  cursor: pointer;
  border: none;
  border-radius: 50px;
  place-content: center;
  align-items: center;
  min-width: 200px;
  min-height: 48px;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  transition: background-color .3s, color .3s, border .3s;
  display: flex;
}

.btn.primary {
  background-color: var(--bordeau);
  color: var(--white);
}

.btn.primary a {
  width: 100%;
}

.btn.primary:hover {
  background-color: var(--btn-hover-color);
}

.btn.outline {
  border: 2px solid var(--white);
  color: var(--white);
  background-color: #0000;
}

.btn.outline:hover {
  background-color: var(--white);
  color: var(--bordeau);
}

.hero .btn.primary {
  border: none;
}

.search-content {
  padding: var(--section-padding-y) 0px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.lead-text {
  color: var(--dark-brown);
  font-size: var(--font-size-base);
}

.search-image {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.search-image img {
  box-shadow: 0 0 24px 1px #322d2929;
}

.search-container {
  margin: var(--img-margin-y);
  flex-direction: column;
  width: 100%;
  display: flex;
}

.search-location {
  background-color: var(--lite-beige);
}

.search-bar {
  background-color: var(--white);
  border: 2px solid var(--input-border-color);
  box-sizing: border-box;
  margin-top: var(--btn-margin-top);
  border-radius: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  max-width: 100%;
  padding: .625rem .687rem;
  display: flex;
}

.search-content .btn.primary {
  align-self: baseline;
  min-width: 50px;
}

.input-field {
  color: var(--input-placeholder-color);
  text-align: left;
  background-color: #0000;
  border: 0;
  outline: 0;
  flex: 1;
  height: 24px;
  padding: 12px;
}

.process-finding-agent {
  background-color: var(--white);
  padding: var(--section-padding-y) 0;
}

.process-finding-agent h2 {
  margin-bottom: 12px;
}

.process-finding-agent .lead-text {
  font-size: var(--font-size-base);
}

.title-container {
  margin-bottom: var(--img-margin-y);
}

.process-finding-agent .lead-text {
  font-size: var(--font-size-h4);
  font-weight: 600;
}

.process-finding-agent .col p {
  margin-bottom: 16px;
  line-height: 1.6;
}

.process-finding-agent .col img {
  border-radius: .5rem;
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 12px #0000001a;
}

.agent-list-standard {
  background-color: var(--lite-beige);
  padding: var(--section-padding-y) 0;
}

.agent-list-standard .row h2 {
  text-align: center;
  margin-bottom: 32px;
}

.agent-list-standard .flex-grid {
  align-items: flex-start;
  display: flex;
}

.agent-list-standard .col img {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}

.agent-list-standard .col .standard-item-row {
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
  display: flex;
}

.agent-list-standard .icon-box {
  background-color: var(--white);
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  padding: 16px;
  display: flex;
  box-shadow: 0 4px 12px #0000001a;
}

.agent-list-standard .standard-icon {
  width: 42px;
  height: 42px;
}

.agent-list-standard .standard-item h3 {
  font-size: var(--font-size-h3);
  color: var(--dark-brown);
  margin-bottom: 8px;
}

.agent-list-standard .standard-item p {
  font-size: var(--font-size-base);
  color: var(--black);
  line-height: 1.6;
}

.agent-list-standard .btn.primary {
  align-self: flex-start;
  margin-top: 24px;
}

.agent-list-standard h2 {
  margin-bottom: 12px;
}

.agent-list-standard .lead-text {
  font-size: var(--font-size-h4);
  font-weight: 600;
}

.agent-list-standard .col p {
  margin-bottom: 16px;
  line-height: 1.6;
}

.referral-commission {
  background-color: var(--white);
  padding: var(--section-padding-y) 0;
}

.commission-box {
  background-color: var(--lite-beige);
  text-align: center;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  max-width: 768px;
  padding: 32px;
  display: flex;
  box-shadow: 0 4px 12px #0000001a;
}

.commission-box h3 {
  font-size: var(--font-size-h3);
  color: var(--dark-brown);
}

.commission-box .lead-text {
  font-size: var(--font-size-base);
}

.commission-box .refer-button {
  border: 2px solid var(--white);
  background-color: var(--bordeau);
  color: var(--white);
  cursor: pointer;
  border-radius: 50px;
  padding: 12px 24px;
  transition: background-color .3s, border .3s;
}

.referral-image {
  border-radius: 8px;
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 12px #0000001a;
}

.meet-your-agent {
  background-color: var(--black);
  border-top-right-radius: var(--video-border-radius);
  border-bottom-left-radius: var(--video-border-radius);
  padding: var(--section-padding-y) 0;
  color: var(--white);
  position: relative;
  overflow: hidden;
}

.meet-your-agent .lead-text {
  font-size: var(--font-size-h4);
  color: var(--white);
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.video-content {
  padding: 0 8%;
}

.video-container {
  max-width: 1200px;
  margin: 0 auto;
}

.video-responsive {
  border-radius: 0 0 0 5vw;
  width: 100%;
  padding: 50.25% 0 0;
  position: relative;
  overflow: hidden;
}

.video-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.why-choose-us {
  padding: var(--section-padding-y) 0px;
}

.why-choose-us .container .col {
  justify-content: center;
  display: flex;
}

.why-choose-us .btn.primary {
  border: 2px solid var(--white);
}

.social {
  background-color: var(--white);
  padding: var(--section-padding-y) 0;
}

.social-box {
  max-width: 750px;
  margin: 0 auto;
}

.social-box h2 {
  font-size: var(--font-size-h2);
  color: var(--dark-brown);
  margin-bottom: 1rem;
}

.social-box .lead-text {
  margin-bottom: 2rem;
  font-size: 1.125rem;
}

.flex-list {
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.social-icon img {
  width: 56px;
  height: 56px;
  transition: transform .3s;
}

.social-icon:hover img {
  transform: scale(1.1);
}

.footer .flex-grid {
  align-items: center;
}

.footer .footer-top {
  background-color: var(--lite-beige);
  padding: var(--section-padding-y) 0;
}

.footer .footer-bottom {
  background-color: var(--white);
  text-align: center;
  color: var(--dark-brown);
  font-size: var(--font-size-base);
  padding: 20px 0;
}

.footer-logo img {
  width: 150px;
  height: auto;
}

.footer-menu li a {
  color: var(--dark-brown);
  text-decoration: none;
  transition: color .3s;
}

.footer-menu li a:hover {
  color: var(--bordeau);
}

.mobile-navbar, .collapsable-menu {
  display: none;
}

.navbar {
  z-index: 90;
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  position: absolute;
  top: 0;
}

.navbar-desktop {
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.logo {
  text-align: center;
  min-width: 150px;
}

.logo a {
  font-size: var(--font-size-h3);
  color: var(--dark-brown);
  font-weight: 700;
  text-decoration: none;
}

.nav-links {
  background-color: var(--lite-beige);
  border-radius: 50px;
  align-items: center;
  gap: 20px;
  height: 64px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  list-style: none;
  display: flex;
}

.nav-links li a {
  color: var(--black);
  background-color: var(--lite-beige);
  border-radius: 50px;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color .3s, color .3s;
}

.nav-links li a:hover {
  color: var(--bordeau);
  background-color: var(--beige);
}

.login-button {
  text-align: center;
  min-width: 150px;
  display: block;
}

.login-button button {
  color: var(--white);
  background-color: var(--bordeau);
  border: 2px solid var(--white);
  text-align: center;
  border-radius: 200px;
  min-width: 6rem;
  max-width: 120px;
  padding: 8px 12px;
  text-decoration: none;
  transition: background-color .3s;
  display: inline-block;
}

.login-button button:hover {
  background-color: var(--btn-hover-color);
}

.right-nav-col {
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.ham-icon {
  width: 1.75rem;
  height: 2rem;
  position: relative;
}

.ham-icon span {
  background-color: var(--black);
  border-radius: 1.75rem;
  width: 100%;
  height: 3px;
  transition: transform .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ham-icon span:first-child {
  top: 25%;
}

.ham-icon span:nth-child(3) {
  top: 75%;
}

.ham-icon.active span:first-child {
  left: 0;
  transform: rotate(45deg)translate(7px, 7px);
}

.ham-icon.active span:nth-child(2) {
  opacity: 0;
}

.ham-icon.active span:nth-child(3) {
  left: 0;
  transform: rotate(-45deg)translate(5px, -5px);
}

.collapsable-menu {
  opacity: 0;
  flex-direction: column;
  max-height: 0;
  transition: max-height .5s, opacity .5s, transform .5s;
  overflow: hidden;
  transform: translateY(-20px);
}

.collapsable-menu.active {
  opacity: 1;
  max-height: 500px;
  transform: translateY(0);
}

@media (width <= 870px) {
  .top-bar {
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    display: flex;
  }

  .navbar {
    background-color: var(--beige);
    text-align: center;
    z-index: 99;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    height: auto;
    padding: 8px 12px;
    transition: transform .5s;
    display: flex;
  }

  .mobile-navbar {
    display: flex;
  }

  .collapsable-menu {
    align-self: center;
    display: flex;
  }

  .collapsable-menu .nav-links {
    text-align: center;
    background-color: #0000;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    display: flex;
  }

  .collapsable-menu nav-links li {
    width: 100%;
  }

  .collapsable-menu .nav-links li a {
    text-align: center;
    font-weight: 600;
    font-size: var(--font-size-h4);
    background-color: #0000;
    width: 100%;
  }

  .mobile-navbar .logo {
    min-width: auto;
    max-width: 100px;
  }

  .right-nav-col {
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .navbar .login-button {
    align-self: center;
    width: 100%;
    display: flex;
  }

  .navbar .login-button button {
    width: 100%;
  }

  .navbar-desktop {
    display: none;
  }

  .hero-buttons {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .login-button {
    min-width: auto;
  }

  .logo {
    margin: 0 1rem;
  }
}

@media (width <= 1200px) {
  .container {
    padding: 0 var(--container-padding-mobile-x);
    flex-direction: column;
    align-items: center;
    max-width: 768px;
    display: flex;
  }

  .search-content {
    flex-direction: column;
    place-content: center;
    align-items: center;
  }

  .hero-content {
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 1rem;
  }

  .btn {
    justify-content: center;
  }

  .search-container .btn.primary {
    align-self: self-start;
    display: flex;
  }

  .flex-grid {
    flex-direction: column;
    gap: 16px;
  }

  .finding-content.flex-grid {
    text-align: center;
    align-items: center;
  }

  .agent-list-standard .col {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .agent-list-standard .col img.agent-list-standard-image {
    width: 100%;
    max-width: 350px;
  }

  .agent-list-standard .flex-grid {
    flex-direction: column;
    align-items: center;
  }

  .agent-list-standard .col {
    width: 100%;
  }

  .agent-list-standard .col .btn.primary {
    justify-content: center;
    width: 100%;
  }

  .agent-list-standard .col .standard-item-row {
    text-align: center;
    flex-direction: column;
    align-items: center;
    max-width: 480px;
  }

  .agent-list-standard .icon-box {
    align-content: center;
    align-items: center;
    width: 100%;
    max-width: 100px;
    height: 100px;
    padding: 12px;
  }

  .icon-box {
    margin-bottom: 16px;
  }

  .standard-item {
    max-width: 100%;
  }

  .referral-commission .flex-grid {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .referral-commission .col {
    justify-content: center;
    width: 100%;
  }

  .referral-commission .commission-box {
    text-align: center;
    align-items: center;
  }

  .referral-commission .commission-box .refer-button {
    justify-content: center;
  }

  .referral-commission .referral-image {
    margin-top: 24px;
  }

  .meet-your-agent {
    border-top-right-radius: var(--video-border-radius);
    border-bottom-left-radius: var(--video-border-radius);
    padding: 32px 0;
  }

  .meet-your-agent .lead-text {
    font-size: var(--font-size-base);
    color: var(--white);
    max-width: 600px;
  }

  .meet-your-agent h2 {
    font-size: var(--font-size-h2);
  }

  .flex-list {
    flex-wrap: wrap;
    gap: 15px;
  }

  .social-icon img {
    width: 35px;
    height: 35px;
  }

  .footer .flex-grid {
    text-align: center;
  }
}

@media (width >= 1440px) {
  .hero {
    min-height: 55vh;
  }
}

@media (width <= 768px) {
  :root {
    --font-size-base: .875rem;
    --font-size-h1: 2.5rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.25rem;
    --font-size-h4: 1rem;
  }

  .footer-menu.flex-list {
    flex-direction: column;
  }
}

@media (width <= 480px) {
  :root {
    --font-size-base: .875rem;
    --font-size-h1: 2.5rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.25rem;
    --font-size-h4: 1rem;
  }

  .btn {
    padding: var(--btn-padding-y) var(--btn-padding-y);
  }

  .search-container .btn.primary {
    align-self: stretch;
    display: flex;
  }

  .agent-list-standard {
    padding: 2rem 0;
  }

  .agent-list-standard .standard-icon {
    width: 42px;
    height: 42px;
  }

  .agent-list-standard .btn.primary {
    margin-top: 16px;
  }

  .referral-commission {
    padding: 2rem 0;
  }

  .commission-box {
    padding: 1.5rem;
  }

  .commission-box .refer-button {
    padding: 10px 20px;
  }

  .meet-your-agent {
    border-top-right-radius: var(--video-border-radius);
    border-bottom-left-radius: var(--video-border-radius);
    padding: 48px 0;
  }

  .flex-list {
    gap: .75rem;
  }

  .social-icon img {
    width: 30px;
    height: 30px;
  }
}

@media (height <= 900px) {
  .hero {
    min-height: 60vh;
  }
}
/*# sourceMappingURL=html.1b27c741.css.map */
